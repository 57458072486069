/* eslint-disable  */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Scrollbar } from 'swiper';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Container from '../Container/Container';
import { replaceArrayImage } from '../../utils/replaceArrayImage';
import bannerSlides from '../../constants/bannerSlides';

import './BuildingDecisions.scss';

SwiperCore.use([Pagination, Scrollbar]);

const BANNER_SLIDES_QUERY = graphql`
  query bannerSlidesQuery {
    allFile(filter: { relativePath: { regex: "/assets/images/bannerSlides/" } }) {
      nodes {
        relativePath
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

const BuildingDecisions: React.FC = () => {
  const {
    allFile: { nodes },
  } = useStaticQuery(BANNER_SLIDES_QUERY);

  const breakpoints = useBreakpoint();

  const slides = replaceArrayImage(bannerSlides, nodes);

  return (
    <div className="banner-slider">
      {breakpoints.laptops ? (
        <Container>
          <div className="banner-slider__wrapper">
            <h2 className="banner-slider__header">Будівельні рішення</h2>
            <p className="banner-slider__text">
              Є будівельна проблема? А ви не знаєте що потрібно для її вирішення? Навіть без професійних знань, ви зможете знайти саме те що
              потрібно вам!
            </p>
            <div className="banner-slider__container">
              {slides.map(({ id, title, link, image, type }) => (
                <div
                  className="banner-slider__slide"
                  onClick={() => window.location.replace(link + (type ? `?type=${type}` : ''))}
                  key={id + title}
                >
                  <Img fluid={image.childImageSharp.fluid} className="banner-slider__slide-image" />
                  <p className="banner-slider__slide-text">{title}</p>
                </div>
              ))}
            </div>
          </div>
        </Container>
      ) : (
        <>
          {' '}
          <div className="banner-slider__title">Будівельні рішення</div>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            direction="horizontal"
            centeredSlides={false}
            className="swiper"
            scrollbar={{
              el: '.swiper-scrollbar',
              draggable: true,
              dragSize: 'auto',
            }}
          >
            {slides.map(({ id, title, link, image, type }) => (
              <SwiperSlide key={id + title}>
                <div className="banner-slider__slide" onClick={() => window.location.replace(link + (type ? `?type=${type}` : ''))}>
                  <Img fluid={image.childImageSharp.fluid} className="banner-slider__slide-image" />
                  <p className="banner-slider__slide-text">{title}</p>
                </div>
              </SwiperSlide>
            ))}
            <SwiperSlide />
          </Swiper>
          <div className="swiper-scrollbar">
            <div className="swiper-scrollbar-drag"></div>
          </div>
        </>
      )}
    </div>
  );
};

export default BuildingDecisions;
