import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { graphql, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { ICockpitImage } from '../Types';
import SliderArrowSVG from '../../assets/icons/slider-arrow.svg';
import Container from '../Container/Container';
import './HeroBanner.scss';

interface IHeroSliderQuery {
  allCockpitHeroSlider: {
    nodes: {
      text: {
        value: string;
      };
      image: {
        value: ICockpitImage;
      };
    }[];
  };
}

const HERO_QUERY = graphql`
  {
    allCockpitHeroSlider(filter: { lang: { eq: "ua" } }) {
      nodes {
        text {
          value
        }
        image {
          value {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

const HeroBanner: React.FC = (): JSX.Element => {
  const {
    allCockpitHeroSlider: { nodes },
  }: IHeroSliderQuery = useStaticQuery(HERO_QUERY);

  const breakpoints = useBreakpoint();
  SwiperCore.use([Autoplay, Navigation]);

  return (
    <section className="hero">
      <Container className="main-banner__container hero_slider">
        <div className="hero__wrapper">
          <div className="hero__navigation hero__navigation_prev">
            <SliderArrowSVG />
          </div>
          <Swiper
            slidesPerView={1}
            grabCursor
            spaceBetween={5}
            loop
            navigation={{
              nextEl: '.hero__navigation_next',
              prevEl: '.hero__navigation_prev',
            }}
            direction="horizontal"
            centeredSlides={false}
            className="hero__swiper"
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            // pagination={{
            //   clickable: true,
            // }}
            // navigation
            // modules={[Autoplay, Pagination, Navigation]}
          >
            {nodes.map((item, i) => (
              <SwiperSlide className={breakpoints.laptops ? 'hero__swiper_slide' : ''} key={i}>
                <div className="hero__item">
                  <p className="hero__item_text">{item.text.value}</p>
                  <div style={{ backgroundImage: `url(${item.image.value.childImageSharp.fluid.src})` }} className="hero__item_image" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="hero__navigation hero__navigation_next">
            <SliderArrowSVG />
          </div>
        </div>
        {/* <div className="main-banner__content">
          <div className="main-banner__content-text">
            <h2>
              ВІДБУДУЄМО <HeartIcon />
              <br /> УКРАЇНУ РАЗОМ
            </h2>
          </div>
          <div className="main-banner__content-info">
            <p>
              Є будівельна проблема? А ви не знаєте що потрібно для її вирішення? Навіть без професійних знань, ви зможете знайти саме те що
              потрібно вам!
            </p>
            <Button
              click={() => {
                clickHandler();
              }}
              height={50}
              htmlType="button"
              type="primary"
            >
              Допомогти з вибором
            </Button>
          </div> 
        </div> */}
      </Container>
    </section>
  );
};

export default HeroBanner;
