import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Container from '../Container/Container';
import ServicesItem from './ServicesItem';
import servicesItems from '../../constants/servicesItems';

import { replaceArrayImage } from '../../utils/replaceArrayImage';

import './Services.scss';

const SERVICES_QUERY = graphql`
  query servicesQuery {
    allFile(filter: { relativePath: { regex: "/assets/images/services/" } }) {
      nodes {
        relativePath
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

const Services: React.FC = () => {
  const {
    allFile: { nodes },
  } = useStaticQuery(SERVICES_QUERY);

  const items = replaceArrayImage(servicesItems, nodes);

  return (
    <section className="services" id="services">
      <Container className="services__container">
        <div className="services__text">
          <h2>Послуги</h2>
          <p>Ми готові надати вам додаткові послуги, аби полегшити ваш ремонт!</p>
        </div>
        <div className="services__items">
          {items.map(({ id, title, description, realm, image }) => (
            <ServicesItem title={title} description={description} realm={realm} image={image} key={id + title} />
          ))}
        </div>
      </Container>
      <div className="blue-circle" />
      <div className="yellow-circle" />
      <div className="blue-circle bottom" />
      <div className="yellow-circle bottom" />
    </section>
  );
};

export default Services;
