export default [
  {
    id: 1,
    title: 'Вивіз сміття',
    description: 'Вивіз будсміття, мотлоху, гілок, меблів, виробничих відходів',
    realm: 'rubish',
    relativePath: 'rubish.png',
  },
  {
    id: 2,
    title: 'Замовлення екскаватору',
    description:
      'На будівельних майданчиках, де передбачені масштабні земельні роботи, не обійтися без потужної землерийно-навантажувальної спецтехніки',
    realm: 'exkavator',
    relativePath: 'exkavator.png',
  },
  {
    id: 3,
    title: 'Замовлення бетону',
    description: 'Шукаєте бетон, який не розкрошиться? Замовляйте у нас!',
    realm: 'beton',
    relativePath: 'beton.png',
  },
  {
    id: 4,
    title: 'Оренда опалубки',
    description: 'Бажаєте зменшити витрати під час будівництва? Візьміть опалубку для стін, для перекриттів, для колони',
    realm: 'opalubka',
    relativePath: 'opalubka.png',
  },
  {
    id: 5,
    title: 'Виклик Спеціаліста на об’єкт',
    description: 'Професійна консультація одразу на вашому об’єкті. Наш спеціаліст підбере всі необхідні матеріали для вашого будівництва',
    realm: 'specialist',
    relativePath: 'specialist.png',
  },
  {
    id: 6,
    title: 'Замовити виконавця будівельних робіт',
    description: 'Професійна консультація одразу на вашому об’єкті. Наш спеціаліст підбере всі необхідні матеріали для вашого будівництва',
    realm: 'builder',
    relativePath: 'builder.png',
  },
];
