import React from 'react';
import './ProductSlider.scss';

import { graphql, useStaticQuery } from 'gatsby';

import ProductSlider from './ProductSlider';
import { IProductsWithStocksQuery } from '../MainStocks/Types';

const PRODUCT_WITH_STOCKS_QUERTY = graphql`
  {
    allCockpitProduct(filter: { stock: { value: { ne: null } }, lang: { eq: "ua" } }) {
      nodes {
        lang
        id
        name {
          value
        }
        stock {
          value
        }
        description {
          value
        }
        price {
          value
        }
        measurment {
          value
        }
        code {
          value
        }
        link {
          value
        }
        brand {
          value
        }
        subcategory {
          value {
            title {
              value
            }
            link {
              value
            }
            category {
              value {
                title {
                  value
                }
                link {
                  value
                }
              }
            }
          }
        }
        image {
          value {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

const DiscountsSlider: React.FC = (): JSX.Element => {
  // const {
  //   allCockpitDiscounts: { nodes },
  // }: IDiscountsSliderQuery = useStaticQuery(DISCOUNTS_QUERY);

  const {
    allCockpitProduct: { nodes },
  } = useStaticQuery<IProductsWithStocksQuery>(PRODUCT_WITH_STOCKS_QUERTY);

  return (
    <div className="discountSlider">
      <ProductSlider nodes={nodes} title="Товари зі знижкою" productKey="discount" />
    </div>
  );
};

export default DiscountsSlider;
