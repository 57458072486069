import React from 'react';
import './ProductSlider.scss';

import { graphql, useStaticQuery } from 'gatsby';
import { IPopularSliderQuery } from './Types';

import ProductSlider from './ProductSlider';

const POPULAR_QUERY = graphql`
  {
    allCockpitPopular(filter: { lang: { eq: "ua" } }) {
      nodes {
        id
        name {
          value
        }
        link {
          value
        }
        price {
          value
        }
        parentId {
          value
        }
        measurment {
          value
        }
        code {
          value
        }
        image {
          value {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        brand {
          value
        }
        category {
          value {
            title {
              value
            }
            link {
              value
            }
          }
        }
      }
    }
  }
`;

const PopularSlider: React.FC = (): JSX.Element => {
  const {
    allCockpitPopular: { nodes: popularNodes },
  }: IPopularSliderQuery = useStaticQuery(POPULAR_QUERY);

  return (
    <div className="popularSlider">
      <ProductSlider nodes={popularNodes} title="Популярні товари" productKey="popular" />
    </div>
  );
};

export default PopularSlider;
