import React from 'react';
import './styles.scss';

import Advantages from '../components/Advantages/Advantages';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import Consult from '../components/Consult/Consult';
import ConsultForm from '../components/ConsultForm/ConsultForm';
import Categories from '../components/Categories/Categories';
import QuestionsForm from '../components/QuestionsForm/QuestionsForm';
import Services from '../components/Services/Services';
import StepsSlider from '../components/StepsSlider/StepsSlider';
import Contacts from '../components/Contacts/Contacts';
import Seo from '../components/SEO/SEO';

import paymentItems from '../constants/paymentItems';
import { SEO_ITEMS } from '../constants/SEOItems';
import BuildingDecisions from '../components/BuildingDecisions/BuildingDecisions';
import PopularCategories from '../components/PopularCategories/PopularCategories';
import PopularSlider from '../components/ProductSlider/PopularSlider';
import RecommendedSlider from '../components/ProductSlider/RecommendedSlider';
import DiscountsSlider from '../components/ProductSlider/DiscountsSlider';

const IndexPage: React.FC = (): JSX.Element => {
  return (
    <div className="home-page">
      <Seo
        breadcrumbs={[
          {
            title: 'Головна',
            link: '/',
          },
        ]}
        description={SEO_ITEMS.indexPage.description}
        lang="ua"
        path="/"
        title={SEO_ITEMS.indexPage.title}
      />
      <HeroBanner />
      <PopularCategories />
      <PopularSlider />
      <DiscountsSlider />
      <RecommendedSlider />

      <BuildingDecisions />
      {/* <Consult /> */}
      {/* <ConsultForm /> */}
      {/* <Categories /> */}
      {/* <Advantages /> */}
      <QuestionsForm />
      <div className="services">
        <Services />
      </div>
      <StepsSlider title="Оплата та доставка" items={paymentItems} />
      <Contacts />
    </div>
  );
};

export default IndexPage;
