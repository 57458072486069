/* eslint-disable import/prefer-default-export */
import Icon1 from '../../assets/about/1.png';
import Icon2 from '../../assets/about/2.png';
import Icon3 from '../../assets/about/3.png';
import Icon4 from '../../assets/about/4.png';
import Icon5 from '../../assets/about/5.png';
import Icon6 from '../../assets/about/6.png';

export const ABOUT_DATA = [
  {
    icon: Icon1,
    title: 'Графік роботи',
    text: 'Професійна консультація та розрахунок матеріалів з 8:00 до 21:00 щоденно',
  },
  {
    icon: Icon2,
    title: '24 області України',
    text: 'Працюємо з 2013 року і реалізуємо будматеріали по всій території України',
  },
  {
    icon: Icon3,
    title: 'Доставка день в день',
    text: 'Зручне розташування складів, оперативна доставка і заніс матеріалів',
  },
  {
    icon: Icon4,
    title: 'Асортимент',
    text: 'Більше 15 000 товарних позицій для ремонтних робіт будь-якої складності',
  },
  {
    icon: Icon5,
    title: 'Оплата',
    text: 'Всі варіанти оплати: готівкою при отриманні, на карту, або безготівковий розрахунок',
  },
  {
    icon: Icon6,
    title: 'Вартість',
    text: 'Завжди вигідні ціни на будматеріали і знижки для оптових клієнтів',
  },
];
