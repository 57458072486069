import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image';

import Button from '../Button/Button';
import ServicesModal from './ServicesModal';

import { IServicesItemProps } from './Types';

import './ServicesItem.scss';

const ServicesItem: React.FC<IServicesItemProps> = ({ title, description, image, realm }) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const clickHandler = () => {
    setModalOpen(true);
    setIsOpened(false);
  };

  useEffect(() => {
    return modalOpen ? document.body.classList.add('fixed') : document.body.classList.remove('fixed');
  }, [modalOpen]);

  return (
    <div
      className={`services-item ${!isOpened ? 'closed' : ''}`}
      onMouseEnter={() => setIsOpened(true)}
      onMouseLeave={() => setIsOpened(false)}
    >
      <div className={`services-item__wrapper ${!isOpened ? 'closed' : ''}`}>
        <div className="services-item__image">
          <Img fluid={image.childImageSharp.fluid} />
        </div>
        <h2 className="services-item__title">{title}</h2>
        <div className={`services-item__description ${!isOpened ? 'closed' : ''}`}>{description}</div>
        <Button type="primary" htmlType="button" color="blue" click={() => clickHandler()}>
          Замовити
        </Button>
      </div>
      <ServicesModal modalOpen={modalOpen} setModalOpen={setModalOpen} realm={realm} title={title} />
    </div>
  );
};

export default ServicesItem;
