import BannerSlideEnum from '../enums/banner-slide.enum';

export default [
  {
    id: 2,
    title: 'Для покрівлі',
    link: '/solutions',
    type: BannerSlideEnum.Pokrivlia,
    relativePath: 'pokrivlia.png',
  },
  {
    id: 3,
    title: 'Для фундаменту ',
    link: '/solutions',
    type: BannerSlideEnum.Fundament,
    relativePath: 'fundament.png',
  },
  {
    id: 4,
    title: 'Для фасаду ',
    link: '/solutions',
    type: BannerSlideEnum.Fasad,
    relativePath: 'fasad.png',
  },
  {
    id: 5,
    title: 'Для стін',
    link: '/solutions',
    type: BannerSlideEnum.Stiny,
    relativePath: 'stiny.png',
  },
  {
    id: 1,
    title: '',
    link: '/solutions',
    relativePath: 'allSolutions.png',
  },
];
