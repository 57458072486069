/* eslint-disable  */

import { FluidObject } from 'gatsby-image';

interface IWithRelativePath {
  relativePath: string;
}

export interface IImageQuery extends IWithRelativePath {
  childImageSharp: {
    fluid: FluidObject;
  };
}

interface IWithImage extends IWithRelativePath {
  image: IImageQuery;
}

export const replaceArrayImage = <T>(arr: (T & IWithRelativePath)[], queryRequest: IImageQuery[]): (T & IWithImage)[] => {
  const items = arr.map(item => ({
    ...item,
    image: queryRequest.find(({ relativePath }: { relativePath: string }) => relativePath.includes(item.relativePath)) as IImageQuery,
  }));
  return items;
};
