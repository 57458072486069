import React from 'react';
import { createPortal } from 'react-dom';
import CloseIcon from '../../assets/icons/close.svg';

import Modal from '../Modal/Modal';
import FeedbackForm from '../FeedbackForm/FeedbackForm';

import { IServicesModalProps } from './Types';

import './ServicesModal.scss';

const ServicesModal: React.FC<IServicesModalProps> = ({ title, modalOpen, setModalOpen }) => {
  return (
    <>
      {modalOpen &&
        createPortal(
          <Modal close={() => setModalOpen(false)}>
            <div className="services-modal">
              <div
                className="services-modal__close"
                onClick={e => {
                  e.stopPropagation();
                  setModalOpen(false);
                  document.body.classList.remove('fixed');
                }}
              >
                <CloseIcon />
              </div>
              <div className="services-modal__text">
                <h2>
                  Замовити послугу
                  <br /> &quot;{title}&quot;
                </h2>
                <p>Заповніть форму і наш менеджер з Вами зв’яжеться для уточнення деталей</p>
              </div>

              <FeedbackForm
                type="services"
                buttonText="Замовити"
                buttonColor="blue"
                checkmarkColor="yellow"
                feedbackTypeColor="black"
                service={title}
              />
            </div>
          </Modal>,
          document.body
        )}
    </>
  );
};

export default ServicesModal;
